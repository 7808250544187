import i18n from "../../../plugins/i18n";
import BaseConfig from "./BaseConfig";

export default class PerformanceConfig extends BaseConfig {
  checkable = true;

  filterDate = true;

  columns = [
    {
      label: i18n.tc("contract"),
      field: "contract.contractNumber",
      component: "StringColumn",
    },
    {
      label: i18n.t("project_date"),
      field: "projectDate",
      component: "DateColumn",
    },
    {
      label: i18n.t("cloud_customer"),
      field: "client.name",
      component: "StringColumn",
    },
    {
      label: i18n.tc("accessory"),
      field: "accessory",
      component: "StringColumn",
    },
    {
      label: i18n.tc("device"),
      field: "device",
      component: "StringColumn",
    },
    {
      label: i18n.tc("user"),
      field: "user.name",
      component: "StringColumn",
    },
    {
      label: i18n.t("field_operators"),
      field: "fieldOperator",
      component: "StringColumn",
    },
    {
      label: i18n.t("pipeline_owner"),
      field: "pipeOwner",
      component: "StringColumn",
    },
    {
      label: i18n.t("pipe_type"),
      field: "pipeType",
      component: "StringColumn",
    },
    {
      label: i18n.t("average_scaling"),
      field: "averageScaling",
      component: "PercentageColumn",
      numeric: true,
    },
    {
      label: i18n.t("spread_xy"),
      field: "observedSpreadPercentageXY",
      component: "SpreadColumn",
      numeric: true,
    },
    {
      label: i18n.t("spread_z"),
      field: "observedSpreadPercentageZ",
      component: "SpreadColumn",
      numeric: true,
    },
  ];
}
