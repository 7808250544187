<template>
  <div>
    <div class="page-header">
      <h1>
        <span>{{ $t("reports") }} &ndash;</span>
        {{ $t("performance") }}
      </h1>
    </div>

    <b-loading v-model="loading"></b-loading>

    <Graphs
      v-if="resultsFilter"
      :results-filter="resultsFilter"
      :settings="settings"
    ></Graphs>

    <FilterAndSettings
      v-if="resultsFilter"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></FilterAndSettings>

    <ResultsTable
      v-if="resultsFilter"
      :loading="loading"
      :config="config"
      :results-filter="resultsFilter"
      :settings="settings"
    ></ResultsTable>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Currencies from "../../helpers/Currencies";
import PerformanceConfig from "./config/PerformanceConfig";
import FilterAndSettings from "./partials/FilterAndSettings";
import Graphs from "./partials/Graphs";
import ResultsFilter from "./partials/ResultsFilter";
import ResultsTable from "./partials/ResultsTable";

export default {
  name: "ReportPerformance",
  components: {
    FilterAndSettings,
    Graphs,
    ResultsTable,
  },
  data() {
    return {
      loading: true,
      config: new PerformanceConfig(),
      resultsFilter: null,
      settings: {
        currency: "EUR",
        conversionRate: Currencies.euroToDollarConversionRate,
        spreadTresholdXY: 0.25,
        spreadTresholdZ: 0.1,
        lengthScalingTreshold: 2.5,
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllMetadata"]),
    async load() {
      const xViewMetadata = await this.getAllMetadata({
        bestScalingRun: true,
      });

      for (const i of xViewMetadata) {
        i.date = i.projectDate;
        i.device = i.serialNumber;
        i.accessory = i.wheelUnitUsed;
      }

      this.resultsFilter = new ResultsFilter(xViewMetadata);

      this.loading = false;
    },
  },
};
</script>
