<template>
  <div class="graph">
    <canvas :id="`canvas${_uid}`" height="200"></canvas>

    <CustomInput
      v-if="metric === 'spread_xy'"
      v-model="settings.spreadTresholdXY"
      :label="$t('treshold')"
      :step="0.01"
      :type="'number'"
      :placeholder="$t('treshold')"
      :required="false"
      :clearable="true"
    ></CustomInput>

    <CustomInput
      v-if="metric === 'spread_z'"
      v-model="settings.spreadTresholdZ"
      :label="$t('treshold')"
      :step="0.01"
      :type="'number'"
      :placeholder="$t('treshold')"
      :required="false"
      :clearable="true"
    ></CustomInput>

    <CustomInput
      v-if="metric === 'length_scaling'"
      v-model="settings.lengthScalingTreshold"
      :label="$t('treshold')"
      :step="0.1"
      :type="'number'"
      :placeholder="$t('treshold')"
      :required="false"
      :clearable="true"
      :min="null"
    ></CustomInput>
  </div>
</template>

<script>
import Chart from "chart.js";
import dayjs from "dayjs";
import CustomInput from "../../../components/forms/CustomInput";

export default {
  components: { CustomInput },
  props: {
    resultsFilter: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    metric: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      graph: null,
    };
  },
  watch: {
    resultsFilter: {
      deep: true,
      immediate: true,
      handler() {
        this.drawChart();
      },
    },
    settings: {
      deep: true,
      immediate: true,
      handler() {
        this.drawChart();
      },
    },
  },
  beforeMount() {
    window.addEventListener("resize", this.windowResized);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResized);
  },
  methods: {
    windowResized() {
      this.drawChart();
    },
    async drawChart() {
      await this.$nextTick();

      if (this.chart) {
        this.chart.destroy();
      }

      const context = document
        .getElementById(`canvas${this._uid}`)
        .getContext("2d");

      const datasets = [];
      datasets.push(this.renderSpread());
      if (
        (this.metric === "spread_xy" && this.settings.spreadTresholdXY) ||
        (this.metric === "spread_z" && this.settings.spreadTresholdZ) ||
        (this.metric === "length_scaling" &&
          this.settings.lengthScalingTreshold)
      ) {
        datasets.push(this.renderTreshold());
      }
      if (
        this.metric === "length_scaling" &&
        this.settings.lengthScalingTreshold
      ) {
        datasets.push(this.renderTreshold(true));
      }

      this.chart = new Chart(context, {
        type: "line",
        data: {
          labels: this.resultsFilter
            .getCheckedFilteredResultsInOrder()
            .map((r) => dayjs(r.projectDate).format("DD-MM-YYYY")),
          datasets,
        },
        options: {
          animation: {
            duration: 0,
          },
          legend: {
            display: false,
          },
          tooltips: {
            filter: function (tooltipItem) {
              // Only show tooltip for first chart, not for tresholds
              return tooltipItem.datasetIndex === 0;
            },
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.yLabel.toFixed(2)} %`;
              },
            },
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Date (DD-MM-YYYY)",
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString:
                    this.metric === "length_scaling"
                      ? "Length scaling (%)"
                      : "Spread (%)",
                },
              },
            ],
          },
          title: {
            display: true,
            text: this.$t(this.metric),
          },
        },
      });
    },
    renderSpread() {
      let metric;
      switch (this.metric) {
        case "spread_xy":
          metric = "observedSpreadPercentageXY";
          break;
        case "spread_z":
          metric = "observedSpreadPercentageZ";
          break;
        case "length_scaling":
          metric = "averageScaling";
          break;
      }

      let color;
      switch (this.metric) {
        case "length_scaling":
        case "spread_xy":
          color = "rgb(89, 129, 184)";
          break;
        case "spread_z":
          color = "rgb(178, 87, 81)";
          break;
      }

      return {
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: color,
        borderWidth: 2,
        hoverRadius: 5,
        pointBackgroundColor: color,
        pointBorderColor: "rgba(0, 0, 0, 0)",
        pointHitRadius: 10,
        data: this.resultsFilter
          .getCheckedFilteredResultsInOrder()
          .map((r) => r[metric]),
      };
    },
    renderTreshold(invert = false) {
      const treshold = {
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderColor: "rgb(78, 172, 91)",
        borderWidth: 2,
        pointBorderColor: "rgba(0, 0, 0, 0)",
      };

      if (this.metric === "spread_xy" && this.settings.spreadTresholdXY) {
        treshold.data = this.resultsFilter
          .getCheckedFilteredResultsInOrder()
          .map(() => this.settings.spreadTresholdXY);
      }

      if (this.metric === "spread_z" && this.settings.spreadTresholdZ) {
        treshold.data = this.resultsFilter
          .getCheckedFilteredResultsInOrder()
          .map(() => this.settings.spreadTresholdZ);
      }

      if (
        this.metric === "length_scaling" &&
        this.settings.lengthScalingTreshold
      ) {
        const coefficient = invert ? -1 : 1;
        treshold.data = this.resultsFilter
          .getCheckedFilteredResultsInOrder()
          .map(() => coefficient * this.settings.lengthScalingTreshold);
      }

      return treshold;
    },
  },
};
</script>
