<template>
  <div class="card graphs mb-5">
    <b-collapse class="panel" animation="slide">
      <template #trigger="props">
        <div class="panel-heading">
          <h2>{{ $t("graphs") }}</h2>
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
      </template>
      <div class="card-content">
        <b-tabs v-model="tab">
          <b-tab-item value="spread" :label="$t('spread')">
            <div class="columns">
              <div class="column is-full is-half-desktop">
                <Graph
                  :results-filter="resultsFilter"
                  :settings="settings"
                  :metric="'spread_xy'"
                ></Graph>
              </div>
              <div class="column is-full is-half-desktop">
                <Graph
                  :results-filter="resultsFilter"
                  :settings="settings"
                  :metric="'spread_z'"
                ></Graph>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item value="length_scaling" :label="$t('length_scaling')">
            <div class="columns">
              <div class="column is-full is-half-desktop">
                <Graph
                  :results-filter="resultsFilter"
                  :settings="settings"
                  :metric="'length_scaling'"
                ></Graph>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Graph from "./Graph";

export default {
  components: {
    Graph,
  },
  props: {
    resultsFilter: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tab: "spread",
    };
  },
};
</script>
